<template>
  <div class="page">
    <div class="page-row">
      <div class="list"
        v-for="(item, index) in basicData" :key="index">
        <div class="title">{{index+1}}. {{item.name}}</div>
        <div class="table">
          <div class="table-tr">
            <div class="table-td">使用目的</div>
            <div class="table-td">{{item.purpose}}</div>
          </div>
          <div class="table-tr">
            <div class="table-td">使用场景</div>
            <div class="table-td">{{item.scene}}</div>
          </div>
          <div class="table-tr">
            <div class="table-td">收集方式</div>
            <div class="table-td">{{item.collectStatus}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import userData from '../utils/user.json';

export default {
  name: 'UserDetails',
  computed: {
    basicData() {
      const { list } = userData.data;
      let data = list.filter((item) => item.group === this.type);
      if (this.isIos) {
        data = data.filter((item) => item.isIos === true);
      }
      if (!this.isIos) {
        data = data.filter((item) => item.isAndroid === true);
      }
      return data;
    },
    type() {
      return this.$route.query?.type;
    },
    name() {
      return this.$route.query?.name;
    },
    isIos() {
      return /i(os|phone|pad)/i.test(navigator.userAgent);
    },
  },
  mounted() {
    document.title = this.name;
  },
};
</script>
<style lang="scss" scoped>
  .page-row {
    padding: 40px 20px 20px 20px;
  }
  .title {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 15px;
  }
  .list {
    margin-top: 20px;
  }
  .table {
    border: 1PX solid rgba(#cccccc, .5);
  }
  .table-tr {
    display: flex;
    border-bottom: 1PX solid rgba(#cccccc, .5);
    &:last-child {
      border-bottom: none;
    }
    .table-td {
      padding: 25px 0;
    }
    .table-td:nth-child(1) {
      width: 22%;
      background-color: #cccccc;
      font-size: 22px;
      font-weight: 700;
      color: #000;
      text-align: center;
      border-right: 1PX solid rgba(#cccccc, .5);
    }
    .table-td:nth-child(2) {
      padding-left: 20px;
    }
  }
</style>
